<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <!-- 항목외 개선 목록 -->
      <c-table
        ref="gridImpr"
        title="LBL0003098"
        :columns="gridImpr.columns"
        :data="gridImpr.data"
        :usePaging="false"
        :filtering="false"
        :columnSetting="false"
        :gridHeight="gridHeight"
        @linkClick="linkClick2"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <!-- 개선요청 -->
            <c-btn v-if="editable && !disabled && popupParam.saiJointInspectionId" label="LBLIMPRREQUEST" :showLoading="false"  icon="add" @btnClicked="addImpr" />
            <!-- 즉시조치 -->
            <c-btn v-if="editable && !disabled && popupParam.saiJointInspectionId" label="LBLIMMEDIATE" :showLoading="false"  icon="add" @btnClicked="addiimImpr" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'safety-check-impr-pop',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        saiJointInspectionId: '',
      }),
    },
    count: {
      type: Number,
      default: 0,
    },
    contentHeight: null,
  },
  data() {
    return {
      editable: true,
      gridImpr: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            // 사업장
            label: 'LBLPLANT',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'ibmClassName',
            field: 'ibmClassName',
            // 구분
            label: 'LBL0003024',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'actionDeptName',
            field: 'actionDeptName',
            // 조치부서
            label: 'LBL0003078',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            // 제목
            label: 'LBLTITLE',
            align: 'left',
            style: 'width:300px',
            sortable: false,
            type: 'link',
          },
          {
            name: 'actionContents',
            field: 'actionContents',
            // 조치내용
            label: 'LBL0003079',
            align: 'left',
            style: 'width:400px',
            sortable: false,
          },
          {
            name: 'actionCompleteDate',
            field: 'actionCompleteDate',
            // 조치완료일
            label: 'LBL0003080',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
        ],
        data: [],
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        closeCallback: null,
      },
      requestImprRow: null,
      imprData: null,
      impListUrl: '',
      getInfoUrl: '',
    };
  },
  computed: {
    gridHeight() {
      let height = 500;
      let _margin = 130;
      if (!this.disabled) {
        _margin += 40
      }
      height = this.contentHeight - _margin
      if (height < 500) {
        height = 500
      }
      return String(height) + 'px';
    },
    disabled() {
      return false;
    },
    // 개선요청 Component
    imprComponent() {
      let components = () => import(`${'@/pages/common/ibm/tableImpr.vue'}`);
      return components;
    },
  },
  watch: {
    'count'() {
      this.getDetail();
      this.getImpList();
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.getInfoUrl = selectConfig.sai.joint.impr.url;
      this.impListUrl = selectConfig.sop.ibm.improve.others.url;

      this.getImpList();
    },
    getImpList() {
      if (this.popupParam.saiJointInspectionId) {
        this.$http.url = this.impListUrl;
        this.$http.param = {
          ibmTaskTypeCd: 'ITT0000165', 
          ibmTaskUnderTypeCd: 'ITTU000175', 
          relationTableKey: this.popupParam.saiJointInspectionId
        };
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.gridImpr.data = _result.data;
        },);
      }
    },
    linkClick(data, row) {
      this.requestImprRow = row
      this.imprData = data;
      if (data.ibmClassCd === 'IC00000001') {
        this.popupOptions.title = 'LBLIMPROVE';
        this.popupOptions.param = {
          sopImprovementId: data.sopImprovementId,
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
        this.popupOptions.width = '80%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeImprPopup;
      } else {
        this.popupOptions.title = 'LBLIMMEDIATE';
        this.popupOptions.param = {
          sopImprovementId: data.sopImprovementId,
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
        this.popupOptions.width = '80%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeImprPopup;
      }
    },
    linkClick2(row) {
      this.linkClick(row, row);
    },
    closeImprPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getImpList();
    },
    research() {
      this.$emit('research');
    },
    // 개선창 닫기 후
    imprChange() {
      this.$refs['grid1'].$refs['compo-table'].resetVirtualScroll();
      this.research();
    },
    addImpr() {
      this.popupOptions.title = 'LBLIMPRREQUEST';
      this.popupOptions.param = {
        requestContents: '합동점검',
        relationTableKey: this.popupParam.saiJointInspectionId,
        ibmTaskTypeCd: 'ITT0000165',
        ibmTaskUnderTypeCd: 'ITTU000175', 
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeAddImprPopup;
    },
    addiimImpr() {
      this.popupOptions.title = 'LBLIMMEDIATE';
      this.popupOptions.param = {
        requestContents: '합동점검',
        relationTableKey: this.popupParam.saiJointInspectionId,
        ibmTaskTypeCd: 'ITT0000165',
        ibmTaskUnderTypeCd: 'ITTU000175', 
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeAddImprPopup;
    },
    closeAddImprPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getImpList();
    }
  }
};
</script>
